import { default as adminsBxiIaTSKSSMeta } from "/tmp/build_b8152f98/pages/administration/admins.vue?macro=true";
import { default as _91id_93doSOL2tfIXMeta } from "/tmp/build_b8152f98/pages/administration/billing/[id].vue?macro=true";
import { default as indexC1GF4IbsKqMeta } from "/tmp/build_b8152f98/pages/administration/billing/index.vue?macro=true";
import { default as bookingsEaNZHMtgq1Meta } from "/tmp/build_b8152f98/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticsHH8HzByGSNMeta } from "/tmp/build_b8152f98/pages/administration/ecostatistics.vue?macro=true";
import { default as exportssOOmfmQHTnMeta } from "/tmp/build_b8152f98/pages/administration/exports.vue?macro=true";
import { default as fidelityQe3HQiz9GRMeta } from "/tmp/build_b8152f98/pages/administration/fidelity.vue?macro=true";
import { default as invoicesn78zvFG1CLMeta } from "/tmp/build_b8152f98/pages/administration/invoices.vue?macro=true";
import { default as policyjZrTcx4DOjMeta } from "/tmp/build_b8152f98/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_939q4q3eqASMMeta } from "/tmp/build_b8152f98/pages/administration/payment/[id].vue?macro=true";
import { default as indexiLgDFi8o4yMeta } from "/tmp/build_b8152f98/pages/administration/payment/index.vue?macro=true";
import { default as statisticslZtBbNX0oJMeta } from "/tmp/build_b8152f98/pages/administration/statistics.vue?macro=true";
import { default as indexmleyYuDEF0Meta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationu76AHwHvajMeta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexoJgpUPHyADMeta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modify9ApM1cx3gOMeta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsgBWJAqkK0dMeta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93cd9VxtdiaJMeta } from "/tmp/build_b8152f98/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexJxiMd482O4Meta } from "/tmp/build_b8152f98/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsRrGmB3sACLMeta } from "/tmp/build_b8152f98/pages/administration/trip-labels.vue?macro=true";
import { default as administrationKI68yJZM37Meta } from "/tmp/build_b8152f98/pages/administration.vue?macro=true";
import { default as swileCyyU0JGT7cMeta } from "/tmp/build_b8152f98/pages/auth/swile.vue?macro=true";
import { default as indexhNIIxFhUbFMeta } from "/tmp/build_b8152f98/pages/bookings/index.vue?macro=true";
import { default as indexcTeGQx5ixIMeta } from "/tmp/build_b8152f98/pages/bookings/validator/index.vue?macro=true";
import { default as pendingBmpV2lDSqoMeta } from "/tmp/build_b8152f98/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93kP1uFw7nIMMeta } from "/tmp/build_b8152f98/pages/car/[id].vue?macro=true";
import { default as exchangeIoMQ07atohMeta } from "/tmp/build_b8152f98/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresGaBTXVzC71Meta } from "/tmp/build_b8152f98/pages/flight/[id]/fares.vue?macro=true";
import { default as indexMYelg815kSMeta } from "/tmp/build_b8152f98/pages/flight/[id]/index.vue?macro=true";
import { default as forgotZ4oBfbw58FMeta } from "/tmp/build_b8152f98/pages/forgot.vue?macro=true";
import { default as get_45swile_45app39Rx8YfiXQMeta } from "/tmp/build_b8152f98/pages/get-swile-app.vue?macro=true";
import { default as helpFa2xAf9SB7Meta } from "/tmp/build_b8152f98/pages/help.vue?macro=true";
import { default as _91id_93msKhr8U3MwMeta } from "/tmp/build_b8152f98/pages/hotel/[id].vue?macro=true";
import { default as _91id_93iP4Dtvi56DMeta } from "/tmp/build_b8152f98/pages/impersonate/[id].vue?macro=true";
import { default as indexITuIixumHAMeta } from "/tmp/build_b8152f98/pages/index.vue?macro=true";
import { default as _91suffix_93L9KP7oMrYhMeta } from "/tmp/build_b8152f98/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93ptZcQxbgVKMeta } from "/tmp/build_b8152f98/pages/invite/[token].vue?macro=true";
import { default as _91id_93Gupfd6NLeiMeta } from "/tmp/build_b8152f98/pages/journeys/[id].vue?macro=true";
import { default as indexAGpag945VPMeta } from "/tmp/build_b8152f98/pages/journeys/index.vue?macro=true";
import { default as membersY6U1VrtQXQMeta } from "/tmp/build_b8152f98/pages/myteam/members.vue?macro=true";
import { default as policyRmJh9b33kRMeta } from "/tmp/build_b8152f98/pages/myteam/policy.vue?macro=true";
import { default as validatorsfLyubpF2VXMeta } from "/tmp/build_b8152f98/pages/myteam/validators.vue?macro=true";
import { default as myteamREkVDByzmZMeta } from "/tmp/build_b8152f98/pages/myteam.vue?macro=true";
import { default as new_45userKyBBPZSHCdMeta } from "/tmp/build_b8152f98/pages/new-user.vue?macro=true";
import { default as passwordvyaf1TABWGMeta } from "/tmp/build_b8152f98/pages/password.vue?macro=true";
import { default as indexk2HHgoc7MkMeta } from "/tmp/build_b8152f98/pages/search/car/[search_id]/index.vue?macro=true";
import { default as index3DVc97hdMOMeta } from "/tmp/build_b8152f98/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93OtZS3st22wMeta } from "/tmp/build_b8152f98/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93ZU0NRSHPJfMeta } from "/tmp/build_b8152f98/pages/search/flight/[search_id].vue?macro=true";
import { default as indexQ4AskjqEYMMeta } from "/tmp/build_b8152f98/pages/search/flight/index.vue?macro=true";
import { default as indexM2NZcrB7cuMeta } from "/tmp/build_b8152f98/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93dnNFz08ZU1Meta } from "/tmp/build_b8152f98/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexvPFAOUetu6Meta } from "/tmp/build_b8152f98/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93m2DnPmnOkFMeta } from "/tmp/build_b8152f98/pages/search/train/[search_id].vue?macro=true";
import { default as indexkMsUv9lV4sMeta } from "/tmp/build_b8152f98/pages/search/train/index.vue?macro=true";
import { default as searchdcfRoKJPOSMeta } from "/tmp/build_b8152f98/pages/search.vue?macro=true";
import { default as indexjdQxiGH8hnMeta } from "/tmp/build_b8152f98/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationayD3nQOpm6Meta } from "/tmp/build_b8152f98/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyG3bp5LGOqfMeta } from "/tmp/build_b8152f98/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsBYRV8vhQPKMeta } from "/tmp/build_b8152f98/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93xtlhepx6CcMeta } from "/tmp/build_b8152f98/pages/teams/[teamId].vue?macro=true";
import { default as indexT4pZlZbRjyMeta } from "/tmp/build_b8152f98/pages/teams/index.vue?macro=true";
import { default as teamsPg4sBH6MmUMeta } from "/tmp/build_b8152f98/pages/teams.vue?macro=true";
import { default as index4vHa3wjX9FMeta } from "/tmp/build_b8152f98/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexSR9wcOOHp2Meta } from "/tmp/build_b8152f98/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as index8Av3GheLuIMeta } from "/tmp/build_b8152f98/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexzW53TohqREMeta } from "/tmp/build_b8152f98/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexhcTCgK8A6QMeta } from "/tmp/build_b8152f98/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseDtTspYvsjjMeta } from "/tmp/build_b8152f98/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitywHfpHKG8uXMeta } from "/tmp/build_b8152f98/pages/users/[id]/fidelity.vue?macro=true";
import { default as indextvZsTM9hdXMeta } from "/tmp/build_b8152f98/pages/users/[id]/index.vue?macro=true";
import { default as passportkAvADfxPE4Meta } from "/tmp/build_b8152f98/pages/users/[id]/passport.vue?macro=true";
import { default as password0UaJjjOJUeMeta } from "/tmp/build_b8152f98/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93pfgk8JX7zDMeta } from "/tmp/build_b8152f98/pages/users/[id].vue?macro=true";
import { default as indexUTyuAjYi1cMeta } from "/tmp/build_b8152f98/pages/users/index.vue?macro=true";
import { default as vouchersBiShDTRAEYMeta } from "/tmp/build_b8152f98/pages/vouchers.vue?macro=true";
import { default as component_45stub4l34Bw9uGtMeta } from "/tmp/build_b8152f98/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub4l34Bw9uGt } from "/tmp/build_b8152f98/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationKI68yJZM37Meta || {},
    component: () => import("/tmp/build_b8152f98/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_b8152f98/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_b8152f98/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_b8152f98/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_b8152f98/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_b8152f98/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_b8152f98/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_b8152f98/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_b8152f98/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_b8152f98/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_b8152f98/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_b8152f98/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_b8152f98/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93cd9VxtdiaJMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexmleyYuDEF0Meta || {},
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modify9ApM1cx3gOMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_b8152f98/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_b8152f98/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileCyyU0JGT7cMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_b8152f98/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexcTeGQx5ixIMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingBmpV2lDSqoMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_b8152f98/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_b8152f98/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_b8152f98/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_b8152f98/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotZ4oBfbw58FMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45app39Rx8YfiXQMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_b8152f98/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_b8152f98/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_b8152f98/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexITuIixumHAMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93L9KP7oMrYhMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93ptZcQxbgVKMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_b8152f98/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_b8152f98/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_b8152f98/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_b8152f98/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_b8152f98/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_b8152f98/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userKyBBPZSHCdMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordvyaf1TABWGMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_b8152f98/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_b8152f98/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_b8152f98/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_b8152f98/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_b8152f98/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_b8152f98/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_b8152f98/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_b8152f98/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_b8152f98/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_b8152f98/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_b8152f98/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teamsPg4sBH6MmUMeta?.name,
    path: "/teams",
    meta: teamsPg4sBH6MmUMeta || {},
    component: () => import("/tmp/build_b8152f98/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_93xtlhepx6CcMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_b8152f98/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_b8152f98/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_b8152f98/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_b8152f98/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_b8152f98/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_b8152f98/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_b8152f98/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_b8152f98/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_b8152f98/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_b8152f98/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_b8152f98/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pfgk8JX7zDMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_b8152f98/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_b8152f98/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_b8152f98/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_b8152f98/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_b8152f98/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_b8152f98/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_b8152f98/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_b8152f98/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_b8152f98/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/metrics",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/en",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/fr",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/pt-BR",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stub4l34Bw9uGt
  },
  {
    name: component_45stub4l34Bw9uGtMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stub4l34Bw9uGt
  }
]